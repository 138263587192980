import ServicesTemplate from "../components/ServicesTemplate";
import { ServicesProps } from "../service";

const config: ServicesProps = {
  title: "About Us",
  sectionHeader:
    "We're empowering businesses with bespoke technology solutions.",
  sectionContent:
    "We have been helping businesses and institutions of all sizes across every sector utilise cutting edge technology to improve accessibility, productivity and connectivity.\n\nWe combine tailored technology solutions with our industry-leading 24/7/365 support and care to provide our clients with the best possible experience.\n\nAs a partner with the best-known brands on the market, we provide the best possible solutions, for the best price.",
  splitTag: "Our approach",
  splitHeading: "Putting our client's needs first",
  splitContent:
    "Our clients are at the heart of what we do. We always go above and beyond to learn about our clients, their requirements, strategies and future plans to make sure we provide the best services possible.\n\nWe always make sure we provide the best value for money to our clients and perform monthly audits on the services we provide.",
  splitImg: "ShardWebScaled",
  servicesHeading: "Our values",
  servicesCards: [
    {
      title: "24/7/365 Support",
      body: "Our dedicated technical support team is available whenever you need us. Our incidence response time is just 3 minutes, and we guarantee to have a solution or workaround to your issues in just 1 hour.",
      img: "PHONE",
    },
    {
      title: "Best Value",
      body: "We are sure you won't find a better price for the solutions or services we provide. If you do find a better price from somewhere else, we will either match the price or give you a better one.",
      img: "POUND",
    },
    {
      title: "Guaranteed Uptime",
      body: "We are confident in the services we provide, so confident that we guarantee 99.96% SLA on our services. That equates to under 5h a year downtime, even for maintenance. We ensure your mission-critical services are always available.",
      img: "CLOUD_CHECK",
    },
    {
      title: "Transparency",
      body: "There are no hidden fees, no small print and no unexpected price changes with goSystem. If your bill is changing, we will give you at least 3 months notice, even if it decreases. You can always find your current",
      img: "GRAPH3",
    },
    {
      title: "New & Better Services",
      body: "We are constantly looking for new and better services to provide. If we think we can improve security, reliability and cost, we will always make the necessary changes to ensure our clients' benefit.",
      img: "GRAPH_INCREASE",
    },
    {
      title: "A Friendly Service",
      body: "We pride ourselves on our customer service. Our professional and friendly team are always here to help our clients in any way we can. No question is too small. We are constantly looking for new ways for clients to get in touch. ",
      img: "HAPPY_MESSAGE",
    },
  ],
  callToAction: "Discover what goSystem can do for you",
};

export default () => ServicesTemplate(config);
